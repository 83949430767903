@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../assets/scss/poloVariables";
@import "../../../../../node_modules/bootstrap/scss/variables";
$background-primary-color: $black;
$background-secondary-color: $gray-900;
$background-contrast-color: $white;
$title-color: $gray-100;
$text-color: $gray-200;

$breadcrumb-color: $gray-500;

$tag-border-color: $gray-800;
$tag-text-color: $text-color;

$footer-background-color: $gray-700;
$footer-text-color: $text-color;

$cardBgColor: $background-primary-color;
$cardBorderColor: $gray-800;
$cardDateBgColor: $gray-800;
$cardDateColor: $white;
$cardInfoColor: $gray-200;
$eventPreRoleColor: $gray-300;
$eventRoleColor: $gray-200;
$eventNameColor: $text-color;
$eventTicketPriceColor: $gray-100;
$eventTicketButtonTextColor: $background-primary-color;
$amazonListActive: $background-primary-color;
$peopleShadow: $background-primary-color;
$galleryCardTextColor: $text-color;
$titleColor: $text-color;

$cardBgColor: $black;
$cardBorderColor: $gray-800;
$cardDateBgColor: $gray-800;
$cardDateColor: $white;
$cardInfoColor: $gray-200;
$eventPreRoleColor: $gray-300;
$eventRoleColor: $gray-200;
$eventNameColor: $gray-100;
$eventTicketPriceColor: $gray-100;
$eventTicketButtonTextColor: $black;
$amazonListActive: $black;
$peopleShadow: $black;
$galleryCardTextColor: $white;
$titleColor: #f1f1f1;

@import "frontendMain";

